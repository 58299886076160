//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import { Swiper, SwiperSlide } from "swiper/react";
//Install
import { Navigation, Pagination, Autoplay } from "swiper/modules";
//Install
import Brand from "../../Content/Brand";
//Install
import { ReactComponent as B463CN } from "../../../Media/Icon/004.svg";
//Install
import { ReactComponent as Y76S2K } from "../../../Media/Icon/005.svg";
//Install
import Q9DG42 from "../../../Media/Video/004.MOV";

//Element
class Default extends Component {
    //Element
    EI13R9(){
        //Element
        let OM175Z     = document.querySelector( "meta[property='og:title']" )
        //Element
        let T00A3U     = document.querySelector( "meta[property='og:description']" )
        //Element
        let HU221M     = document.querySelector( "meta[property='og:image']" )
        //Element
        let V337GE     = document.querySelector( "meta[property='og:url']" )
        //Element
        OM175Z.content = "AIM | Cover-Studio"
        //Element
        T00A3U.content = "Showcase your work at the cover of the market."
        //Element
        HU221M.content = "https://cover-studio.com/favicon.svg"
        //Element
        V337GE.content = "https://cover-studio.com/aim"
    }
    //Element
    Q8283L(){
        //Element
        let N09HE5     = document.querySelector( "meta[name='twitter:title']" )
        //Element
        let D68001     = document.querySelector( "meta[name='twitter:description']" )
        //Element
        let N94MJ9     = document.querySelector( "meta[name='twitter:image']" )
        //Element
        let S577FO     = document.querySelector( "meta[name='twitter:url']" )
        //Element
        N09HE5.content = "AIM | Cover-Studio"
        //Element
        D68001.content = "Showcase your work at the cover of the market."
        //Element
        N94MJ9.content = "https://cover-studio.com/favicon.svg"
        //Element
        S577FO.content = "https://cover-studio.com/aim"
    }
    //Element
    N2782S(){
        //Element
        let AHF319     = document.querySelector( "meta[itemprop='name'" )
        //Element
        let W2LB44     = document.querySelector( "meta[itemprop='description']" )
        //Element
        let K08577     = document.querySelector( "meta[itemprop='image']" )
        //Element
        AHF319.content = "About Us | Cover-Studio"
        //Element
        W2LB44.content = "Showcase your work at the cover of the market."
        //Element
        K08577.content = "https://cover-studio.com/favicon.svg"
    }
    //Element
    componentDidMount(){
        //Element
        document.title                                                                    = "AIM | Cover-Studio"
        //Element
        document.getElementsByTagName( "meta" )["description"].content                    = "Showcase your work at the cover of the market."
        //Element
        document.querySelectorAll( "link[rel='canonical']" )[0].setAttribute( "href", "https://cover-studio.com/aim" )
        //Element
        this.EI13R9()
        //Element
        this.Q8283L()
        //Element
        this.N2782S()
    }
    //Element
    render() {
        //Element
        const References = {
            //Element
            spaceBetween    : "12",
            //Element
            slidesPerView   : "6",
            //Element
            navigation      : {
                //Element
                nextEl      : "#Web_D19GM2",
                //Element
                prevEl      : "#Web_Z188E9"
            },
            //Element
            autoplay        : {
                //Element
                delay       : 5000
            },
            //Element
            loop            : true,
            //Element
            freeMode        : true,
            //Element
            breakpoints     : {
                //Element
                320:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                360:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                480:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                504:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                600:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                768:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                1024:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                1280:{
                    //Element
                    slidesPerView : 6
                }
            },
            //Element
            modules         : [ Navigation, Pagination, Autoplay ]
        }
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 s288ei" },
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 o747gy" },
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 position-relative s40258" },
                    //Element
                    React.createElement( "video", { className: "float-start w-100 h-auto", src: Q9DG42, playsInline: true, controls: false, loop: true, autoPlay: true, muted: false } ),
                    //Element
                    React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 position-absolute start-0 top-0 uf9065" },
                        //Element
                        React.createElement( Container, {},
                            //Element
                            React.createElement( Row, {},
                                //Element
                                React.createElement( Col, { md:12 },
                                    //Element
                                    React.createElement( "h1", { className: "float-start w-100 p-0 m-0 text-center c137ai" },
                                        //Element
                                        "‘Showcase your work at the cover of the market’"
                                    )
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 oj1a97" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:9 },
                            //Element
                            React.createElement( "div", { className: "d-flex flex-column justify-content-start align-items-start p-0 m-0 mt-5 u2k9z3" },
                                //Element
                                React.createElement( "h2", { className: "float-start w-auto p-0 m-0 lh-1 x11q7x" },
                                    //Element
                                    "AIM"
                                ),
                                //Element
                                React.createElement( "p", { className: "float-start w-auto p-0 m-0 mt-2 h8697j" },
                                    //Element
                                    React.createElement( "strong", {},
                                        //Element
                                        "Pushing the boundaries of design in the luxury world, "
                                    ),
                                    //Element
                                    "experimenting with new techniques, materials, and technologies."
                                ),
                                //Element
                                React.createElement( "hr", {} ),
                                //Element
                                React.createElement( "p", { className: "float-start w-auto p-0 m-0 mt-2 h8697j" },
                                    //Element
                                    React.createElement( "strong", {},
                                        //Element
                                        "Creating memorable experiences for our clients. Cover Studio "
                                    ),
                                    //Element
                                    "go beyond aesthetics and focus on crafting experiences that evoke emotions and leave a lasting impact. From immersive installations to interactive digital interfaces, our designs engage and captivate audiences, making them stand out in a crowded marketplace."
                                ),
                                //Element
                                React.createElement( "hr", {} ),
                                //Element
                                React.createElement( "p", { className: "float-start w-auto p-0 m-0 mt-2 h8697j" },
                                    //Element
                                    React.createElement( "strong", {},
                                        //Element
                                        "Practical and user-friendly. "
                                    ),
                                    //Element
                                    "By combining their artistic vision with a deep understanding of user needs, ",
                                    //Element
                                    React.createElement( "strong", {},
                                        //Element
                                        "Cover Studio "
                                    ),
                                    //Element
                                    "create solutions that are both visually stunning and highly functional."
                                ),
                                //Element
                                React.createElement( "hr", {} ),
                                //Element
                                React.createElement( "p", { className: "float-start w-auto p-0 m-0 mt-2 h8697j" },
                                    //Element
                                    React.createElement( "strong", {},
                                        //Element
                                        "Staying ahead of trends : Cover Studio "
                                    ),
                                    //Element
                                    "keep a finger on the pulse of emerging design movements, consumer preferences, and technological advancements. By staying informed and continuously learning, they are able to anticipate future demands and deliver cutting-edge designs that resonate with their target audience."
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 h-100 flex-column justify-content-between align-items-center p-0 m-0 d-none g3285g" },
                                //Element
                                React.createElement( "figure", { className: "float-start w-100 p-0 m-0 mt-5 vv75g0" },
                                    //Element
                                    React.createElement( "img", { alt: "AIM", title: "AIM", src: "https://images.pexels.com/photos/3194518/pexels-photo-3194518.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1", width: "261", height: "auto" } )
                                ),
                                //Element
                                React.createElement( "figure", { className: "float-start w-100 p-0 m-0 mt-4 f4ab27" },
                                    //Element
                                    React.createElement( "img", { alt: "AIM", title: "AIM", src: "https://images.pexels.com/photos/6335/man-coffee-cup-pen.jpg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1", width: "261", height: "auto" } )
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 i84t5x" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 z1520t" },
                                //Element
                                React.createElement( "h2", { className: "float-start w-100 p-0 m-0 text-center q5s25u" },
                                    //Element
                                    "Our Clients"
                                )
                            ),
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 mb-4 z1520t" },
                                //Element
                                React.createElement( Swiper, { className: "float-start w-100 position-relative overflow-hidden", ...References },
                                    //Element
                                    React.createElement( SwiperSlide, {},
                                        //Element
                                        React.createElement( Brand, { ID: "0", Title: "", Image: "fendi.jpg", Target: "/" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, {},
                                        //Element
                                        React.createElement( Brand, { ID: "0", Title: "", Image: "fauchon.jpg", Target: "/" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, {},
                                        //Element
                                        React.createElement( Brand, { ID: "0", Title: "", Image: "chopard-logo.jpg", Target: "/" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, {},
                                        //Element
                                        React.createElement( Brand, { ID: "0", Title: "", Image: "GILAN2.jpg", Target: "/" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, {},
                                        //Element
                                        React.createElement( Brand, { ID: "0", Title: "", Image: "pamellata.jpg", Target: "/" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, {},
                                        //Element
                                        React.createElement( Brand, { ID: "0", Title: "", Image: "Kiehls-1851-Logo_Black1.jpg", Target: "/" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, {},
                                        //Element
                                        React.createElement( Brand, { ID: "0", Title: "", Image: "fendi.jpg", Target: "/" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, {},
                                        //Element
                                        React.createElement( Brand, { ID: "0", Title: "", Image: "fauchon.jpg", Target: "/" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, {},
                                        //Element
                                        React.createElement( Brand, { ID: "0", Title: "", Image: "chopard-logo.jpg", Target: "/" } )
                                    )
                                ),
                                //Element
                                React.createElement( "div", { className: "d-flex w-100 justify-content-between align-items-center p-0 m-0 position-absolute start-0 top-0 gx42v3" },
                                    //Element
                                    React.createElement( "button", { id: "Web_Z188E9", name: "Web_Z188E9", className: "d-flex w-100 justify-content-center align-items-center position-relative rounded-circle ty74f3", type: "button" },
                                        //Element
                                        React.createElement( Y76S2K, {} )
                                    ),
                                    //Element
                                    React.createElement( "button", { id: "Web_D19GM2", name: "Web_D19GM2", className: "d-flex w-100 justify-content-center align-items-center position-relative rounded-circle x781vv", type: "button" },
                                        //Element
                                        React.createElement( B463CN, {} )
                                    )
                                )
                            )
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Default;