//Install
import React, { Component } from "react";
//Install
import { Row, Col } from "react-bootstrap";

//Element
class Copyright extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( Row, {},
            //Element
            React.createElement( Col, { md:6 },
                //Element
                React.createElement( "div", { className: "d-flex w-100 justify-content-start align-items-center p-0 m-0 r8038t" },
                    //Element
                    React.createElement( "p", { className: "float-start w-auto p-0 m-0 m1c5x2" },
                        //Element
                        "Copyright © 2025 Cover-Studio |"
                    ),
                    //Element 
                    React.createElement( "a", { className: "float-start w-auto p-0 ps-1 m-0 m1c5x2", href: "https://b4mind.com/" },
                        //Element
                        "Made With ",
                        //Element
                        React.createElement( "img", { alt: "B4MIND", title: "B4MIND", src: "https://cdn.shopify.com/s/files/1/0107/1041/7465/files/heart_large.png?v=1531497480", width: "16", height: "14" } ),
                        //Element
                        " by B4MIND"
                    )
                )
            ),
            //Element
            React.createElement( Col, { md:6 },
                //Element
                React.createElement( "menu", { className: "d-flex w-100 justify-content-end align-items-center p-0 m-0 d-none bz803k" },
                    //Element
                    React.createElement( "li", {},
                        //Element
                        React.createElement( "a", { className: "float-start w-auto p-0 m-0 me-4", title: "", href: "/" },
                            //Element
                            "Privacy Policy"
                        )
                    ),
                    //Element
                    React.createElement( "li", {},
                        //Element
                        React.createElement( "a", { className: "float-start w-auto p-0 m-0", title: "", href: "/" },
                            //Element
                            "Cookie Policy"
                        )
                    ),
                    //Element
                    //React.createElement( "li", {},
                        //Element
                        //React.createElement( "a", { className: "float-start w-auto p-0 m-0", title: "", href: "/" },
                            //Element
                            //"Ethics Hotline"
                        //)
                    //)
                )
            )

        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Copyright;